import React from 'react';
import styled from 'styled-components';

import upDrawingImagePath from '../images/upDrawing.png';
import upBidDrawingPath from '../images/bidDrawing.png';
import keywordDrawingPath from '../images/keywordsDrawing.png';
import WidthWrapper from '../components/WidthWrapper';

const Container = styled.div`
  display: flex;
  margin-bottom: 66px;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 104px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const FeatureHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.870588);
  margin-top: 19px;
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    margin-top: 58px;
  }
  @media screen and (min-width: 900px) {
    margin-top: 27px;
  }
`;

const FeatureImage = styled.img`
  @media screen and (max-width: 900px) {
    width: auto;
    max-width: 160px;
    align-self: center;
  }
  @media screen and (min-width: 900px) {
    max-width: 205px;
    max-height: 125px;
    align-self: center;
  }
`;

const FeatureDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  max-width: 300px;
  margin-top: 19px;
`;

const FeaturesMain = () => (
  <WidthWrapper>
    <Container>
      <FeatureWrapper>
        <FeatureImage src={upBidDrawingPath} />
        <FeatureHeading>
          Set optimal bids
        </FeatureHeading>
        <FeatureDescription>
          Update keyword bids based on thousands of data points. Maximising your ad budgets returns.
        </FeatureDescription>
      </FeatureWrapper>

      <FeatureWrapper>
        <FeatureImage src={upDrawingImagePath} />
        <FeatureHeading>
          Insights through reporting
        </FeatureHeading>
        <FeatureDescription>
          More effective Ad spend through product and campaing level performance reporting.
        </FeatureDescription>
      </FeatureWrapper>

      <FeatureWrapper>
        <FeatureImage src={keywordDrawingPath} />
        <FeatureHeading>
          Discover new keywords
        </FeatureHeading>
        <FeatureDescription>
          Automatically discover and
          move well performing keywords from
          automatic to manual campaigns. Isolating
          bids for the most precise budget allocation.
        </FeatureDescription>
      </FeatureWrapper>
    </Container>
  </WidthWrapper>
);

export default FeaturesMain;
