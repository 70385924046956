import React from 'react';
import styled from 'styled-components';

import WidthWrapper from '../components/WidthWrapper';
import Button from '../components/Button';
import ExternalLink from '../components/ExternalLink';

import loopIconPath from '../images/icons/loop.svg';
import historyIconPath from '../images/icons/history.svg';
import discoveryIconPath from '../images/icons/discovery.svg';
import reportsIconPath from '../images/icons/reports.svg';
import negativesIconPath from '../images/icons/negatives.svg';
import productIconPath from '../images/icons/product.svg';


const Container = styled.div`
  margin-bottom: 80px
`;

const Heading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.870588);
  margin-bottom: 27px;
`;

const SmallHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.870588);
  align-items: center;
  display: flex;
`;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeaturesRow = styled.div`
  @media screen and (min-width: 900px) {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
  }
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const FeatureText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135.7%;
  color: rgba(0, 0, 0, 0.6);
  max-width: 228px;
  margin-left: 35px;
  margin-top: 5px;
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
`;


const SignupButton = styled(Button)`
  background: linear-gradient(
    137.8deg,
    #0051f0 0%,
    #00c9f0 99.99%,
    #0051f0 100%
  );
  text-align: center;
  width: 165px;
  margin: 0 auto;
  border-radius: 4px;
  @media screen and (min-width: 900px) {
    margin-top: 49px
  }

  @media screen and (max-width: 900px) {
    margin-top: 56px;
  }
`;

const Features = () => (
  <WidthWrapper>
    <Container>
      <Heading>Make your ad budget effective</Heading>
      <FeaturesRow>
        <FeaturesColumn>
          <Feature>
            <SmallHeading>
              <Icon src={loopIconPath} />
              Bid optimisation
            </SmallHeading>
            <FeatureText>
              Calculate and update the optimal bid daily to maximise your returns.
            </FeatureText>
          </Feature>
          <Feature>
            <SmallHeading>
              <Icon src={historyIconPath} />
              Unlimited History
            </SmallHeading>
            <FeatureText>
              Inspect all available performance data without Amazons reporting limits.
            </FeatureText>
          </Feature>
        </FeaturesColumn>
        <FeaturesColumn>
          <Feature>
            <SmallHeading>
              <Icon src={discoveryIconPath} />
              Keyword Discovery
            </SmallHeading>
            <FeatureText>
              Discover new keywords first and profit from low competition.
            </FeatureText>
          </Feature>
          <Feature>
            <SmallHeading>
              <Icon src={reportsIconPath} />
              Scheduled Reports
            </SmallHeading>
            <FeatureText>
              Keep stakeholders in the loop with scheduled email reports.
            </FeatureText>
          </Feature>
        </FeaturesColumn>
        <FeaturesColumn>
          <Feature>
            <SmallHeading>
              <Icon src={negativesIconPath} />
              Updated Negatives
            </SmallHeading>
            <FeatureText>
              Identify bad performing keywords, auto add them to negatives.
            </FeatureText>
          </Feature>
          <Feature>
            <SmallHeading>
              <Icon src={productIconPath} />
              Product Level Insight
            </SmallHeading>
            <FeatureText>
              Know how individual products perform to allocate budgets smarter.
            </FeatureText>
          </Feature>
        </FeaturesColumn>
      </FeaturesRow>
      <ExternalLink href="https://calendly.com/lars_bidmatik/bidmatik-product-demo?month=2019-07">
        <SignupButton>
          GET PRODUCT DEMO
        </SignupButton>
      </ExternalLink>
    </Container>
  </WidthWrapper>
);

export default Features;
