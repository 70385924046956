import React from 'react';
import styled from 'styled-components';

import Button from '../components/Button';
import WidthWrapper from '../components/WidthWrapper';
import ExternalLink from '../components/ExternalLink';

import BackgroundShapeOrangeRoundPath from '../images/shapeOrangeRound.svg';

const Container = styled.div`
  background: linear-gradient(135.91deg, #0051F0 0%, #00C9F0 99.99%, #0051F0 100%);
  height: 405px;
  @media screen and (max-width: 900px) {
    height: 350px;
  }
`;

const WidthWrapperStyled = styled(WidthWrapper)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Heading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #FFFFFF;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
  margin-top: 78px;
`;

const GetStartedButton = styled(Button)`
  background: linear-gradient(135deg, #FF9805 0%, #F64C55 100%);
  text-transform: uppercase;
  text-align: center;
  width: 150px;
`;

const ExternalLinkStyled = styled(ExternalLink)`
  @media screen and (max-width: 900px) {
    align-self: center;
  }
`;

const BackgroundShape = styled.div`
  background-image: url(${BackgroundShapeOrangeRoundPath});
  height: 673px;
  position: absolute;
  width: 903px;
  @media screen and (min-width: 900px) {
    transform: translateX(-87%);
  }
  @media screen and (max-width: 900px) {
    transform: translateX(-96%);
  }
  overflow: visible;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const CtaBanner = () => (
  <Container>
    <BackgroundShape />
    <WidthWrapperStyled>
      <HeadingWrapper>
        <Heading>
          Try it for free.
        </Heading>
        <Heading>
          14 Days without credit card.
        </Heading>
      </HeadingWrapper>
      <ExternalLinkStyled href="https://calendly.com/lars_bidmatik/bidmatik-product-demo?month=2019-07">
        <GetStartedButton>
          schedule your demo
        </GetStartedButton>
      </ExternalLinkStyled>
    </WidthWrapperStyled>
  </Container>
);

export default CtaBanner;
